import "../styles/globals.css";

import Script from "next/script";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// TODO: Uncomment once we have user session
// import { UserProvider } from "../context/User";

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID;

function MyApp({ Component, pageProps }: any) {
  return (
    // <UserProvider>
    <>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTAG_ID}', {'debug_mode':${
          process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
        }});
            `}
      </Script>
      <div className="w-screen overflow-hidden flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow">
          <Component {...pageProps} />
        </div>
        <Footer />
      </div>
    </>
    // </UserProvider>
  );
}

export default MyApp;
