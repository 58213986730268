import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import clsx from "clsx";
import { useRouter } from "next/router";

export function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const route = useRouter();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [route.pathname]);

  return (
    <>
      <div className="relative py-6 px-4 sm:px-6 lg:px-8">
        <nav
          className="relative flex items-center justify-between sm:h-10 lg:justify-start max-w-7xl m-auto"
          aria-label="Global"
        >
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link href="/">
                <a className="h-10">
                  <span className="sr-only">Coach Mate</span>
                  <Image width={40} height={40} src="/logo.png" alt="logo" />
                </a>
              </Link>
              {/* <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  id="main-menu"
                  aria-haspopup="true"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>

                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
          <div className="w-full flex justify-between">
            <div className="flex items-center ml-4 md:ml-10 md:pr-4 md:space-x-8">
              <Link href="/pricing">
                <a className="font-medium  text-gray-900 hover:text-gray-600">
                  Cennik
                </a>
              </Link>
              {/* <Link href="/">
                <a className="font-medium text-gray-500 hover:text-gray-900">
                  O nas
                </a>
              </Link>
              <Link href="/">
                <a className="font-medium text-gray-500 hover:text-gray-900">
                  Kontakt
                </a>
              </Link> */}
            </div>
            <div>
              <a
                href={`${process.env.NEXT_PUBLIC_PANEL_URL}/login`}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Zaloguj się
              </a>
            </div>
          </div>
        </nav>
      </div>
      {/* <Transition
        show={mobileMenuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <Image width={30} height={30} src="/logo.png" alt="logo" />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Zamknij menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link href="/pricing">
                <a className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
                  Plany treningowe
                </a>
              </Link>
            </div>
            <div className={clsx({ hidden: loading })}>
              {user ? (
                <>
                  <div className="w-full h-px bg-gray-100" />
                  <div className="w-full px-5 py-3 flex justify-center items-center">
                    <img
                      src={user.pictureUrl}
                      className="rounded-full h-8 w-8 mr-2"
                    />
                    <span className="font-medium text-gray-500">
                      {user.email || user.firstName}
                    </span>
                  </div>
                  <button
                    onClick={logout}
                    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                  >
                    Wyloguj się
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setMobileMenuOpen(false);
                    setLoginModalOpen(true);
                  }}
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                >
                  Zaloguj się
                </button>
              )}
            </div>
          </div>
        </div>
      </Transition> */}
    </>
  );
}
